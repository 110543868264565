import { Component, Vue, Watch } from 'vue-property-decorator';
import Layout from '@/components/layouts/main.vue';
import PageTitle from '@/components/ui/page-title/pageTitle.vue';
import { Action } from 'vuex-class';
import { SpotType } from '@/types/api/spot/spot.type';
import { SpotUpdateRequest } from '@/types/request/spot-request.types';
import { SpotCard } from '@/store/modules/spot/actions';
import SpotForm from '@/components/pages/spots/spot-form/spotForm.vue';
import { InfoActivityRequest } from '@/types/request/info-request.types';
import { ListResponseType } from '@/types/response/list-response.type';
import { ActivityType } from '@/types/api/info/activity.type';
import { ActivityCard } from '@/store/modules/activity/actions';
import { ActivityCreateRequest } from '@/types/request/activity-request.types';
import { SpotActivityType } from '@/types/api/activity/spot-activity.type';
import AddActivity from '@/components/ui/modals/addActivity.vue';
import ShowActivitiesWidget from '@/components/ui/show-activities-widget/showActivitiesWidget.vue';
import FormBlock from '@/components/ui/form-block/form-block.vue';
import { ColDef, GridOptions, ICellRendererParams, ValueGetterParams } from 'ag-grid-community';
import { ICallbackAdditionalParams, ICallbackGeneralParams } from '@/helpers/ag-grid/ag-grid-datasource';
import { AgGridHelper } from '@/helpers/ag-grid/ag-grid.helper';
import { IOfferCard } from '@/store/modules/offer/actions';
import { OfferSpotRequest } from '@/types/request/offer-request.types';
import { SpotOfferType } from '@/types/api/offer/spot-offer.type';
import { AgGridVue } from 'ag-grid-vue';
import { DATE_FORMAT, getDateFromISO } from '@/helpers/date.helper';
import AddOffer from '@/components/ui/modals/addOffer.vue';

@Component({
  components: {
    Layout,
    PageTitle,
    SpotForm,
    ShowActivitiesWidget,
    FormBlock,
    AgGridVue
  },
})
export default class SpotUpdate extends Vue {
  spotId!: number;
  spot: SpotType | null = null;
  activities: SpotActivityType[] = [];
  agGridOffers: AgGridHelper | null = null;

  @Action('getSpot', { namespace: 'spot' })
  getSpot!: (id: number) => Promise<SpotType>;

  @Action('updateSpot', { namespace: 'spot' })
  updateSpot!: (data: SpotCard<SpotUpdateRequest>) => Promise<SpotType>;

  @Action('deleteSpot', { namespace: 'spot' })
  deleteSpot!: (id: number) => Promise<SpotType>;

  @Action('getActivities', { namespace: 'info' })
  getActivities!: (params: InfoActivityRequest) => Promise<ListResponseType<ActivityType>>;

  @Action('activitySpotCreate', { namespace: 'activity' })
  activitySpotCreate!: (data: ActivityCard<ActivityCreateRequest>) => Promise<SpotActivityType>;

  @Action('activitySpotDelete', { namespace: 'activity' })
  activitySpotDelete!: (id: number) => Promise<SpotActivityType>;

  @Action('getOfferSpots', { namespace: 'offer' })
  getOfferSpots!: (data: IOfferCard<OfferSpotRequest>) => Promise<ListResponseType<SpotOfferType>>;

  @Action('deleteOfferSpot', { namespace: 'offer' })
  deleteOfferSpot!: (id: number) => Promise<SpotOfferType>;

  @Watch('currentLang')
  resetGridHeader(newValue: string, oldValue: string) {
    if (this.agGridOffers && newValue !== oldValue) {
      this.agGridOffers.getGridApi()!.refreshHeader();
    }
  }

  created(): void {
    if (this.$route.params.id) {
      this.spotId = Number(this.$route.params.id);
      this.getSpot(this.spotId)
        .then(result => {
          this.spot = result;
          this.activities = result.spotActivities || [];
          return result;
        })
        .catch(() => this.goToSpots());
    } else {
      this.goToSpots();
    }
  }

  beforeMount() {
    this.agGridOffers = new AgGridHelper(this.initGridOptionsOptions())
      .enableAutoSizeColumns(false)
      .enableInfinity(this.gridOffersChangeCallback)
      .build();
  }

  goToSpots() {
    this.$router.push({ name: 'spots' });
  }

  onApply(params: SpotUpdateRequest) {
    return this.updateSpot({
      id: this.spotId,
      params: params
    })
      .then(() => {
        this.goToSpots();
      });
  }

  onDeleteSpot() {
    this.$modal.show('dialog', {
      title: '',
      text: this.$t('common.deleteQuestion'),
      buttons: [
        {
          title: 'Ok',
          handler: () => {
            this.$modal.hide('dialog');
            this.deleteSpot(this.spot!.id)
              .then(() => this.goToSpots());
          }
        },
        {
          title: this.$t('common.cancel')
        }
      ]
    });
  }

  onAddActivity() {
    this.$modal.show(AddActivity, {
      updateCallback: (result: any) => {
        return this.activitySpotCreate({ id: this.spot!.id, params: result })
          .then(response => this.activities.push(response));
      },
      activitiesAction: (search: string) => this.getActivities({
        take: 20,
        skip: 0,
        name: search
      })
        .then(result => result.items.map(item => ({ id: item.id, title: item.name, item }))),
    }, {
      adaptive: true,
      height: 'auto',
    });
  }

  onDeleteActivity(activity: ActivityType) {
    this.$modal.show('dialog', {
      title: '',
      text: this.$t('common.deleteQuestion'),
      buttons: [
        {
          title: 'Ok',
          handler: () => {
            this.$modal.hide('dialog');
            this.activitySpotDelete(activity.id)
              .then(response => {
                this.activities = this.activities.filter(item => item.id !== response.id);
              });
          }
        },
        {
          title: this.$t('common.cancel')
        }
      ]
    });
  }

  gridOffersChangeCallback(general: ICallbackGeneralParams, additional: ICallbackAdditionalParams) {
    additional.api.showLoadingOverlay();

    return this.getOfferSpots({
      id: this.spotId,
      params: {
        take: general.limit,
        skip: general.offset,
      }
    })
      .then(response => {
        additional.api.hideOverlay();
        return {
          result: response.items,
          count: response.paging.totalCount
        }
      });
  }

  initGridOptionsOptions(): GridOptions {
    return {
      columnDefs: this.offersColumnDef,
      enableSorting: false,
      suppressHorizontalScroll: true,
    }
  }

  get offersColumnDef(): ColDef[] {
    return [
      {
        headerValueGetter: () => this.$t('offer.name'),
        field: 'name',
        cellRenderer: 'linkCellRenderer',
        cellRendererParams: {
          url: (params: ICellRendererParams) => params.data && `/offer/${params.data.id}?type=spot`,
          text: (params: ICellRendererParams) => params.data && params.data.locales[0]?.name,
        }
      },
      {
        headerValueGetter: () => this.$t('offer.limit'),
        field: 'limit',
      },
      {
        headerValueGetter: () => this.$t('offer.startAt'),
        field: 'startAt',
        valueGetter: (params: ValueGetterParams) => {
          return params.data && getDateFromISO(params.data.startAt, DATE_FORMAT);
        }
      },
      {
        headerValueGetter: () => this.$t('offer.endAt'),
        field: 'endAt',
        valueGetter: (params: ValueGetterParams) => {
          return params.data && getDateFromISO(params.data.endAt, DATE_FORMAT);
        }
      },
      {
        headerValueGetter: () => this.$t('offer.expiresAt'),
        field: 'expiresAt',
        valueGetter: (params: ValueGetterParams) => {
          return params.data && getDateFromISO(params.data.expiresAt, DATE_FORMAT);
        }
      },
      {
        headerName: '',
        field: 'actions',
        minWidth: 110,
        maxWidth: 110,
        width: 110,
        cellRenderer: 'actionsCellRenderer',
        cellRendererParams: {
          buttons: [
            {
              type: 'link',
              actionCallback: this.onDeleteOffer,
              btnContent: '<i class="fe-trash-2 text-danger"></i>',
            },
          ]
        }
      },
    ];
  }

  onAddOffer() {
    this.$modal.show(AddOffer, {
      title: this.$t('offer.addTitle'),
      id: this.spotId,
      isSpot: true,
      updateCallback: () => {
        this.agGridOffers?.refreshInfinity();
      },
    }, {
      adaptive: true,
      height: 'auto',
      width: '80%',
    });
  }

  onDeleteOffer(params: ICellRendererParams) {
    this.$modal.show('dialog', {
      title: '',
      text: this.$t('common.deleteQuestion'),
      buttons: [
        {
          title: 'Ok',
          handler: () => {
            this.$modal.hide('dialog');
            this.deleteOfferSpot(params.data.id)
              .then(() => {
                this.agGridOffers?.refreshInfinity();
              })
          }
        },
        {
          title: this.$t('common.cancel')
        }
      ]
    });
  }
}
